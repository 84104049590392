import React from "react"
import Layout from "../components/Layout"
import Eja from "../components/Inscricoes/eja"
import AnuncioEja from "../components/Anuncios/eja"

import { LeftWrapper, RightWrapper } from "../components/Layout/styled"

import SEO from "../components/seo"

const EjaEadPage = () => (
  <Layout>
    <SEO title="Inscrição Eja EAD" />
      <LeftWrapper>
        <Eja />
      </LeftWrapper>
      <RightWrapper>
        <AnuncioEja />
      </RightWrapper>
  </Layout>
)

export default EjaEadPage
