import React from 'react'
import MaskedInput from 'react-text-mask'
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Logo from '../../images/logo-metodologia-ead.png'

toast.configure()

const Eja = () => {

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('gmail', 'template_afj114b', e.target, 'user_VjStSBhZtjVYh6RRiS6gl')
      .then((result) => {
        toast.success("Envio com sucesso. Sua matrícula será concluída assim que um dos nossos consultores entrar em contato!")
      }, (error) => {
        toast.info("Ooops! Há algo de errado, por favor ligue para secretaria (82) 98719-5545.")
      });
  }

  const showData = (result) => {
    for(const campo in result) {
        if (document.querySelector('#'+campo)) {
            document.querySelector('#'+campo).value = result[campo]
        }
    }
}

function findAddress(ev) {
    const { value } = ev.target
    if (value?.length !== 9) {
        return;
    }

    fetch(`https://viacep.com.br/ws/${value}/json/`)
    .then((res) => res.json())
    .then((data) => showData(data))
}

  return (
    <div className="flex flex-col w-full">
      <ToastContainer />
      <div className="flex-1 flex flex-col p-5 md:p-5 items-center">
        <img src={Logo} alt="Logo Metodologia EAD" className="w-24 md:w-36"/>
        <h1 className="md:text-2xl text-color_one font-bold mt-1">Ficha de Inscrição Eja EAD</h1>
        <div className="flex flex-col w-full md:px-8">
          <form onSubmit={sendEmail}>

          <div className="flex flex-col md:flex-row md:justify-between items-center">
              <div className="flex flex-col w-full">
                <h2 className="text-color_one text-xs mt-2">Nome Completo</h2>
                <input
                  className=" w-full outline-none px-1 text-xs"
                  type="text"
                  name="from_nome"
                  required
                />
              </div>
              <div className="flex flex-col w-full md:w-1/3 ml-0 md:ml-2">
                <h2 className="text-color_one text-xs mt-2">Sexo</h2>
                <select
                  className=" w-full outline-none px-1 text-xs"
                  type="text"
                  name="from_sexo"
                  required
                >
                  <option value="---">---</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Feminino">Feminino</option>
                </select>
              </div>
            </div>
            
            <div className="flex justify-between items-center">
              <div className="flex flex-col w-full">
                <h2 className="text-color_one text-xs mt-2">Identidade<span style={{fontSize: "8px", marginLeft: 2}}>(Nº e Órgão Expedidor)</span></h2>
                <input
                  className=" w-full outline-none px-1 text-xs"
                  type="text"
                  name="from_identidade"
                  required
                />
              </div>
              <div className="flex flex-col w-2/2 md:w-1/3 ml-2">
                <h2 className="text-color_one text-xs mt-2">CPF</h2>
                <MaskedInput
                  mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/,]}
                  className=" w-full outline-none px-1 text-xs"
                  type="text"
                  name="from_cpf"
                  required
                />
              </div>
            </div>

            <div className="flex justify-between items-center">
              <div className="flex flex-col w-2/4">
                <h2 className="text-color_one text-xs mt-2">Nome da Mãe</h2>
                <input
                  className=" w-full outline-none px-1 text-xs"
                  type="text"
                  name="from_nomedamae"
                  required
                />
              </div>
              <div className="flex flex-col w-2/4 ml-2">
                <h2 className="text-color_one text-xs mt-2">Nome do Pai</h2>
                <input
                  className=" w-full outline-none px-1 text-xs"
                  type="text"
                  name="from_nomedopai"
                  required
                />
              </div>
            </div>

              <div className="flex justify-between items-center">
              <div className="flex flex-col w-2/4">
                  <h2 className="text-color_one text-xs mt-2">Data de Nascimento</h2>
                  <input
                    className=" w-full outline-none px-1 text-xs"
                    type="date"
                    name="from_datadenascimento"
                    required
                  />
                </div>
              <div className="flex flex-col w-2/4 ml-2">
                <h2 className="text-color_one text-xs mt-2">Nacionalidade</h2>
                <input
                  className=" w-full outline-none px-1 text-xs"
                  type="text"
                  name="from_nacionalidade"
                  required
                />
              </div>
            </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-full">
                  <h2 className="text-color_one text-xs mt-2">Naturalidade <span style={{fontSize: '10px'}}>(Cidade de Nascimento)</span></h2>
                  <input
                    className=" w-full outline-none px-1 text-xs"
                    type="text"
                    name="from_naturalidade"
                    required
                  />
                </div>
                <div className="flex flex-col w-14 ml-2">
                  <h2 className="text-color_one text-xs mt-2">UF</h2>
                  <input
                    className="w-full outline-none px-1 text-xs"
                    type="text"
                    name="from_uf"
                  />
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-32">
                  <h2 className="text-color_one text-xs mt-2">Cep</h2>
                  <MaskedInput
                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/,]}
                    className=" w-full outline-none px-1 text-xs"
                    type="text"
                    name="from_cep"
                    onBlur={findAddress}
                    id="cep"
                    required
                  />
                </div>
                <div className="flex flex-col w-full ml-2">
                  <h2 className="text-color_one text-xs mt-2">Logradouro</h2>
                  <input
                    className="w-full outline-none px-1 text-xs"
                    type="text"
                    name="from_logradouro"
                    id="logradouro"
                    required
                  />
                </div>
                <div className="flex flex-col w-20 ml-2">
                  <h2 className="text-color_one text-xs mt-2">Nº</h2>
                  <input
                    className="w-full outline-none px-1 text-xs"
                    type="text"
                    name="from_numero"
                  />
                </div>                
              </div>

                <div className="flex flex-col w-full">
                  <h2 className="text-color_one text-xs mt-2">Complemento</h2>
                  <input
                    className=" w-full outline-none px-1 text-xs"
                    type="text"
                    name="from_complemento"
                  />
                </div>

                <div className="flex justify-between">
                <div className="flex flex-col w-full">
                  <h2 className="text-color_one text-xs mt-2">Bairro</h2>
                  <input
                    className=" w-full outline-none px-1 text-xs"
                    type="text"
                    name="from_bairro"
                    id="bairro"
                    required
                  />
                </div>
                <div className="flex flex-col w-full ml-2">
                  <h2 className="text-color_one text-xs mt-2">Cidade</h2>
                  <input
                    className="w-full outline-none px-1 text-xs"
                    type="text"
                    name="from_cidade"
                    id="localidade"
                    required
                  />
                </div>
                <div className="flex flex-col w-28 ml-2">
                  <h2 className="text-color_one text-xs mt-2">UF</h2>
                  <input
                    className="w-full outline-none px-1 text-xs"
                    type="text"
                    name="from_enderecouf"
                    id="uf"
                    required
                  />
                </div>                
              </div>
              
              <div className="flex flex-col w-full">
                  <h2 className="text-color_one text-xs mt-2">Email</h2>
                  <input
                    className=" w-full outline-none px-1 text-xs"
                    type="email"
                    name="from_email"
                    required
                  />
                </div>

                <div className="flex flex-col md:flex-row md:justify-between items-center">
              <div className="flex flex-col w-full md:w-2/4">
                <h2 className="text-color_one text-xs mt-2">Telefone Celular</h2>
                <MaskedInput
                   mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  className=" w-full outline-none px-1 text-xs"
                  type="tel"
                  name="from_telefonecelular"
                  required
                />
              </div>
              <div className="flex flex-col w-full md:w-2/4 ml-0 md:ml-2">
                <h2 className="text-color_one text-xs mt-2">Telefone Residencial</h2>
                <MaskedInput
                  mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  className=" w-full outline-none px-1 text-xs"
                  type="tel"
                  name="from_telefoneresidencial"
                />
              </div>
              <div className="flex flex-col w-full md:w-2/4 ml-0 md:ml-2">
                <h2 className="text-color_one text-xs mt-2">Telefone Comercial</h2>
                <MaskedInput
                  mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  className=" w-full outline-none px-1 text-xs"
                  type="tel"
                  name="from_telefonecomercial"
                />
              </div>
            </div>

            <div className="flex flex-col items-center w-full mt-5">
              <div className="flex flex-col w-3/4">
                <input
                  className="inputButton text-sm"
                  type="submit"
                  value="Enviar Inscrição"
                />
              </div>
            </div>

            <hr className="mt-3"/>

            <div>
              <p className="text-xs mt-2">OBS: O cancelamento da inscrição com devolução de taxa somente será feito até 2 dias úteis antes do início do curso.</p>
            </div>

            <hr className="mt-2"/>
            
            <div>
              <p className="text-xs mt-2">Documentos Necessários
                Ao Finalizar a inscrição enviar os seguintes documentos via whatsapp;
              </p>
              <ul>
                <li className="text-xs">• RG</li>
                <li className="text-xs">• CPF</li>
                <li className="text-xs">• TITULO ELEITORAL</li>
                <li className="text-xs">• COMPROVANTE DE RESIDÊNCIA</li>
                <li className="text-xs">• 02 FOTOS ¾</li>
              </ul>
              <p className="hidden md:flex text-xs mt-2">Enviar para secretaria Whatsapp = (82) 98719-5545</p>
              <p className="md:hidden text-xs mt-2">Enviar para secretaria Whatsapp<br /> (82) 98719-5545</p>
            </div>

            <hr className="mt-2"/>
            
            <p className="text-xs mt-2">OBS: O CERTIFICADO SÓ SERÁ ENTREGUE COM A CÓPIA DE TODOS OS DOCUMENTOS ENTREGUES NO INSTITUTO</p>

          </form>
        </div>
      </div>

      <footer className="flex items-center place-items-center w-full h-6 ">
        <p style={{fontSize: 10, marginLeft: 10}}>&copy; {new Date().getFullYear()}, Metodologia EAD</p>
      </footer>
    </div>
  )
}

export default Eja
